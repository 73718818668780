import '../new-item/NewItem.scss';
import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { Button, Drawer, Input, Modal, Table, message } from 'antd';
import firebase from 'firebase/compat/app';
import { getDownloadURL } from 'firebase/storage';
import PropTypes from 'prop-types';
import { Children, Component, cloneElement } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCompanies } from '../../actions/company.actions';
import { setDrawer } from '../../actions/env.actions';
import { createGedUser, getUsers } from '../../actions/user.actions';

const { Search } = Input;

class NewInstanceInner extends Component {
    constructor(props) {
        super(props);
        this.createGed = this.createGed.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
        this.handleBdgChange = this.handleBdgChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleCompanyOk = this.handleCompanyOk.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleRejectChange = this.handleRejectChange.bind(this);
        this.handleRejectOk = this.handleRejectOk.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUserOk = this.handleUserOk.bind(this);
        this.hideDrawer = this.hideDrawer.bind(this);
        this.resetSearch = this.resetSearch.bind(this);
        this.showBdgModal = this.showBdgModal.bind(this);
        this.showCompaniesModal = this.showCompaniesModal.bind(this);
        this.showDrawer = this.showDrawer.bind(this);
        this.showGedModal = this.showGedModal.bind(this);
        this.showRejectModal = this.showRejectModal.bind(this);
        this.showUsersModal = this.showUsersModal.bind(this);
        this.state = { bdgs: [] };
    }

    handleBdgChange(value) {
        firebase
            .firestore()
            .collection('bdgs')
            .where('vmiRef', '==', value.toLocaleUpperCase())
            .get()
            .then((snapshot) => {
                const bdgs = [];
                snapshot.docs.forEach((s) => {
                    const toAdd = s.data();
                    toAdd.docId = s.id;
                    bdgs.push(toAdd);
                });
                bdgs.sort((a, b) => b.installDate.seconds - a.installDate.seconds);
                this.setState({ bdgs });
            });
    }

    handleCompanyChange(event) {
        const text = event.target.value.toLocaleLowerCase();
        const { companies, isUsers } = this.props;
        const filtredCompanies = companies.filter((company) => company.mail.toLocaleLowerCase().includes(text)
            || company.name.toLocaleLowerCase().includes(text)
            || `${company.siren}`.toLocaleLowerCase().includes(text)
            || `${company.phone}`.toLocaleLowerCase().includes(text)).map((c) => (isUsers ? c.firebaseId : c));
        if (filtredCompanies.length) this.setState({ companies: filtredCompanies });
        else this.setState({ companies: null });
    }

    handleCompanyOk(isOK) {
        const { companies } = this.state;
        const { getCompanies: getCompaniesAction, intl, refreshFunction, isUsers } = this.props;
        this.setState({ isCompaniesVisible: false });
        if (isOK && (companies || []).length) {
            this.setState({ searching: true });
            if (isUsers) refreshFunction(companies);
            else getCompaniesAction(companies);
        } else if (isOK) message.error(intl.formatMessage({ id: 'company_empty' }));
    }

    handlePhoneChange(event) {
        this.setState({ phone: event.target.value });
    }

    handleRejectChange(event) {
        this.setState({ rejectId: event.target.value });
    }

    handleRejectOk() {
        const { rejectId } = this.state;
        firebase
            .firestore()
            .doc('tests/rejects')
            .get()
            .then((snapshot) => {
                const rejectList = snapshot.data().list;
                rejectList.push(rejectId);
                firebase
                    .firestore()
                    .doc('tests/rejects')
                    .update({ list: rejectList })
                    .then(() => {
                        this.setState({ isRejectVisible: false });
                        message.success('Rebus ajouté avec succès');
                    });
            });
    }

    handleSubmit(e) {
        const {
            agency,
            company,
            createFunction,
            form: { resetFields, validateFields },
            intl,
            messages: { createSuccess },
            refreshFunction,
            role,
        } = this.props;
        e.preventDefault();
        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const values2 = values;
                values2.lang = intl.locale;
                createFunction(values2, agency).then(async () => {
                    resetFields();
                    this.hideDrawer();
                    refreshFunction(company, role, agency);
                    message.success(intl.formatMessage({ id: `message_success_${createSuccess}` }));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
    }

    handleUserOk(isOK) {
        const { getUsers: getUsersAction, intl } = this.props;
        const { phone } = this.state;
        this.setState({ isUsersVisible: false });
        if (isOK && phone) {
            this.setState({ searching: true });
            getUsersAction(phone).then((response) => {
                if (!response) message.error(intl.formatMessage({ id: 'users_empty' }));
            });
        }
    }

    createGed() {
        const { users } = this.props;
        let hasGedUser;
        users.forEach((user) => {
            if ('ged' === user.nativeRole) hasGedUser = user;
        });
        this.showGedModal(hasGedUser);
    }

    downloadCsv() {
        const { userName } = this.props;
        firebase.functions().httpsCallable('companiesDownload')({ id: userName }).then((response) => {
            getDownloadURL(firebase.storage().ref(response.data.filePath)).then((url) => window.open(url, '_blank')).catch((error) => message.error(error));
        }, (error) => console.error(error));
    }

    hideDrawer() {
        const { form: { resetFields }, isUsers, setDrawer: setDrawerAction } = this.props;
        resetFields();
        this.setState({ drawerIsVisible: false });

        if (isUsers) setDrawerAction(false);
    }

    resetSearch() {
        const { company, refreshFunction, isUsers } = this.props;
        this.setState({ searching: false });
        if (isUsers) refreshFunction(company);
        else refreshFunction();
    }

    showBdgModal() {
        this.setState({ isBdgVisible: true });
    }

    showDrawer() {
        const { companies, getCompanies: getCompaniesAction, role } = this.props;
        if (companies.length <= 1 && role === 'admin') getCompaniesAction().then(() => {
            this.setState({ drawerIsVisible: true });
        });
        else this.setState({ drawerIsVisible: true });
    }

    showGedModal(hasGedUser) {
        const { company, intl, refreshFunction } = this.props;

        Modal.confirm({
            cancelText: intl.formatMessage({ id: 'global_no' }),
            okText: intl.formatMessage({ id: 'global_yes' }),
            okType: 'primary',
            title: hasGedUser ? intl.formatMessage({ id: 'ged_createQuestion_exist' }) : intl.formatMessage({ id: 'ged_createQuestion' }),
            onOk() {
                createGedUser(company, intl.locale, hasGedUser).then(async () => {
                    await refreshFunction(company);
                    message.success(intl.formatMessage({ id: 'message_success_create_user' }));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                });
            },
        });
    }

    showCompaniesModal() {
        const { companies, getCompanies: getCompaniesAction } = this.props;
        if (companies.length <= 1) getCompaniesAction().then(() => {
            this.setState({ isCompaniesVisible: true });
        });
        else this.setState({ isCompaniesVisible: true });
    }

    showRejectModal() {
        this.setState({ isRejectVisible: true });
    }

    showUsersModal() {
        this.setState({ isUsersVisible: true });
    }

    render() {
        const { bdgs, drawerIsVisible, isBdgVisible, isCompaniesVisible, isRejectVisible, isUsersVisible, loading, searching } = this.state;
        const { children, form, hasSelectedUserRole, intl, isUsers, messages: { createAction }, role, setDrawer: setDrawerAction } = this.props;

        const bdgColumns = [
            {
                dataIndex: 'installDate',
                render: (date) => new Date(date.seconds * 1000).toLocaleDateString('fr', { hour: 'numeric', minute: 'numeric' }),
                title: intl.formatMessage({ id: 'document_client_doc_creation_date' }),
            },
            {
                dataIndex: 'companyName',
                title: intl.formatMessage({ id: 'company_name' }),
            },
            {
                key: 'action',
                render: (_, record) => <a href={`/bdg-generator/${record.docId}`} label="fez" rel="noreferrer" target="_blank"><FormattedMessage id="bdg_button" /></a>,
            },
        ];

        return (
            <>
                <div className="actionsContainer">
                    {'admin' === role && !isUsers && (
                        <>
                            <Button onClick={this.showCompaniesModal}><FormattedMessage id="company_search" /></Button>
                            <Modal onCancel={() => this.handleCompanyOk()} onOk={() => this.handleCompanyOk(true)} open={isCompaniesVisible} title={intl.formatMessage({ id: 'company_search' })}>
                                <Input onChange={this.handleCompanyChange} placeholder={intl.formatMessage({ id: 'company_search_placeholder' })} />
                            </Modal>
                            <Button onClick={this.downloadCsv} type="primary">
                                <FormattedMessage id="companies_download" />
                            </Button>
                            {searching && (<Button onClick={this.resetSearch} type="danger"><FormattedMessage id="user_search_reset" /></Button>)}
                        </>
                    )}
                    {!searching && isUsers && (
                        <Button onClick={this.showDrawer} type="primary">
                            <FormattedMessage id={createAction} />
                        </Button>
                    )}
                    {'admin' === role && isUsers && (
                        <>
                            <Button onClick={this.showRejectModal}>Ajouter un rebus</Button>
                            <Modal onCancel={() => this.setState({ isRejectVisible: false })} onOk={() => this.handleRejectOk()} open={isRejectVisible} title="Ajouter un rebus">
                                <Input onChange={this.handleRejectChange} placeholder="Id du rebus" />
                            </Modal>

                            <Button onClick={this.showUsersModal}><FormattedMessage id="user_search" /></Button>
                            <Modal onCancel={() => this.handleUserOk()} onOk={() => this.handleUserOk(true)} open={isUsersVisible} title={intl.formatMessage({ id: 'user_search' })}>
                                <Input onChange={this.handlePhoneChange} placeholder={intl.formatMessage({ id: 'user_search_placeholder' })} />
                            </Modal>

                            <Button onClick={this.showCompaniesModal}><FormattedMessage id="company_search" /></Button>
                            <Modal onCancel={() => this.handleCompanyOk()} onOk={() => this.handleCompanyOk(true)} open={isCompaniesVisible} title={intl.formatMessage({ id: 'company_search' })}>
                                <Input onChange={this.handleCompanyChange} placeholder={intl.formatMessage({ id: 'company_search_placeholder' })} />
                            </Modal>

                            <Button onClick={this.showBdgModal}><FormattedMessage id="bdg_search" /></Button>
                            <Modal cancelButtonProps={{ style: { display: 'none' } }} onCancel={() => this.setState({ isBdgVisible: false })} onOk={() => this.setState({ isBdgVisible: false })} open={isBdgVisible} title={intl.formatMessage({ id: 'bdg_search' })}>
                                <Search onSearch={this.handleBdgChange} placeholder={intl.formatMessage({ id: 'bdg_search_placeholder' })} enterButton />
                                <Table columns={bdgColumns} dataSource={bdgs} pagination={{ hideOnSinglePage: true, showSizeChanger: false }} rowKey="installDate" style={{ marginTop: 16 }} />
                            </Modal>
                        </>
                    )}
                    {'adminClient' === role && (
                        <Button onClick={this.createGed}>
                            <FormattedMessage id="createGed_title" />
                        </Button>
                    )}
                </div>
                {isUsers && (
                    <Drawer
                        closable={false}
                        onClose={this.hideDrawer}
                        open={drawerIsVisible}
                        title={<FormattedMessage id={createAction} />}
                    >
                        <Form layout="vertical" onSubmit={this.handleSubmit}>
                            {Children.map(children, (element) => cloneElement(element, { form, hasSelectedUserRole, setDrawerAction }))}
                            <div className="form-buttons">
                                <Button onClick={this.hideDrawer} style={{ marginRight: 8 }}>
                                    <FormattedMessage id="button_cancel" />
                                </Button>
                                <Button htmlType="submit" loading={loading} onClick={this.onClose} type="primary">
                                    <FormattedMessage id="button_send" />
                                </Button>
                            </div>
                        </Form>
                    </Drawer>
                )}
            </>
        );
    }
}

NewInstanceInner.propTypes = {
    agency: PropTypes.string,
    children: PropTypes.node,
    companies: PropTypes.arrayOf(PropTypes.shape({
        firebaseId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    company: PropTypes.string,
    createFunction: PropTypes.func.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        resetFields: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    getCompanies: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    hasSelectedUserRole: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    isUsers: PropTypes.bool,
    messages: PropTypes.shape({
        createAction: PropTypes.string.isRequired,
        createSuccess: PropTypes.string.isRequired,
    }).isRequired,
    refreshFunction: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    setDrawer: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

NewInstanceInner.defaultProps = {
    isUsers: false,
};

const mapDispatchToProps = { getCompanies, getUsers, setDrawer };
const mapStateToProps = ({ companies: { companies }, env: { hasSelectedUserRole }, signIn: { agency, company, role, userName }, users: { users } }) => ({ agency, company, companies, role, userName, hasSelectedUserRole, users });

export default connect(mapStateToProps, mapDispatchToProps)(Form.create()(injectIntl(NewInstanceInner)));
