import 'firebase/compat/analytics';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import 'firebase/compat/storage';
import firebase from 'firebase/compat/app';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';
import ReactDOM from 'react-dom';
import App from './App';

if (process.env.NODE_ENV !== 'production') {
    if (!firebase.apps.length) firebase.initializeApp({
        apiKey: 'AIzaSyAMQmFwsZJzl9XinHWV5s5TtXrE95QqRPE',
        appId: '1:157655115535:web:9430e17bd0ab2d1764407a',
        authDomain: 'portail-web-staging.firebaseapp.com',
        databaseURL: 'https://portail-web-staging.firebaseio.com',
        measurementId: 'G-TWLL7JN8S3',
        messagingSenderId: '157655115535',
        projectId: 'portail-web-staging',
        storageBucket: 'portail-web-staging.appspot.com',
    });
    const remoteConfig = getRemoteConfig();
    fetchAndActivate(remoteConfig).then(() => {
        firebase.auth().onAuthStateChanged((isLoggin) => {
            if (isLoggin) localStorage.setItem('isLoggin', true);
            else localStorage.removeItem('isLoggin');
            ReactDOM.render(
                <App />,
                document.getElementById('root'),
            );
        });
    });
} else fetch('/__/firebase/init.json').then(async (response) => {
    if (!firebase.apps.length) {
        const responseJson = await response.json();
        firebase.initializeApp(responseJson);
        firebase.analytics();
    }
    const remoteConfig = getRemoteConfig();
    remoteConfig.settings = { minimumFetchIntervalMillis: 0 };
    fetchAndActivate(remoteConfig).then(() => {
        firebase.auth().onAuthStateChanged((isLoggin) => {
            if (isLoggin) localStorage.setItem('isLoggin', true);
            else localStorage.removeItem('isLoggin');
            ReactDOM.render(
                <App />,
                document.getElementById('root'),
            );
        });
    });
});
