import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = ({ signIn: { loggedIn } }) => ({ loggedIn });

const requireAuth = (ComposedComponent) => {
    class auth extends Component {
        componentDidMount() {
            this.checkLoggedIn();
        }

        componentDidUpdate() {
            this.checkLoggedIn();
        }

        checkLoggedIn() {
            const { history: { push } } = this.props;
            if (JSON.parse(localStorage.getItem('isLoggin'))) push('/hello');
        }

        render() {
            if (JSON.parse(localStorage.getItem('isLoggin'))) return null;

            return <ComposedComponent {...this.props} />;
        }
    }

    auth.propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    return connect(mapStateToProps)(auth);
};

export default requireAuth;
