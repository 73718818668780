import './Documents.scss';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getDocuments, setDocumentsAdmin } from '../../actions/documents.actions';
import PageLayout from '../layout/PageLayout';
import DocumentsTable from './DocumentsTable';

class Documents extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableIsLoading: false,
        };
    }

    componentDidMount() {
        const {
            setDocumentsAdmin: setDocumentsAdminAction,
            getDocuments: getDocumentsAction,
            role,
        } = this.props;
        if ('admin' === role) setDocumentsAdminAction();

        getDocumentsAction();
    }

    render() {
        const { tableIsLoading } = this.state;
        const {
            history: { goBack },
            intl,
        } = this.props;

        return (
            <PageLayout className="documentManagement" goBack={goBack} pageKey="documentManagement" title={intl.formatMessage({ id: 'hello_documents_clients' })}>
                <DocumentsTable loading={tableIsLoading} />
            </PageLayout>
        );
    }
}

Documents.propTypes = {
    getDocuments: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    intl: PropTypes.any.isRequired,
    role: PropTypes.string.isRequired,
    setDocumentsAdmin: PropTypes.func.isRequired,
};

const mapStateToProps = ({ companies: { company, companies, adminClient: { maxUsers, activeUsers } }, signIn: { role, userName } }) => ({ company, companies, role, userName, maxUsers, activeUsers });

const mapDispatchToProps = (dispatch) => ({
    getDocuments: () => dispatch(getDocuments()),
    setDocumentsAdmin: () => dispatch(setDocumentsAdmin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Documents));
