const defaultState = {
    videos: [],
};

const videosReducer = (state = defaultState, { type, videos }) => {
    switch (type) {
        case 'VIDEOS_LIST_SET':
            return { ...state, videos };
        default:
            return state;
    }
};

export default videosReducer;
