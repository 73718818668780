import firebase from 'firebase/compat/app';

const setVideosList = (videos) => ({
    videos,
    type: 'VIDEOS_LIST_SET',
});

export const deleteVideo = (firebaseId) => {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
        db.collection('videos').doc(firebaseId).delete()
            .then(() => {
                resolve(true);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

export const getVideos = () => (dispatch) => {
    const db = firebase.firestore();
    db.collection('videos').orderBy('title').get().then(async (querySnapshot) => {
        const datas = [];
        if (querySnapshot.size > 0) querySnapshot.docs.forEach(async (doc, i) => {
            datas.push({
                ...doc.data(),
                firebaseId: doc.id,
            });
            if (i === querySnapshot.size - 1) dispatch(setVideosList(datas));
        });
        else dispatch(setVideosList(datas));
    });
};

export const setVideoLink = async (values) => {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
        db.collection('videos').doc().set({
            ...values,
            timestamp: new Date(),
        })
            .then(() => resolve('upload_video'))
            .catch((e) => reject(e));
    });
};
