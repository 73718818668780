import storage from 'redux-persist/lib/storage';

const defaultState = { locale: 'fr' };

const appReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'LOCALE_SET':
            return { locale: payload.locale };
        default:
            return state;
    }
};

const rootReducer = (state, action) => {
    if ('DESTROY_SESSION' === action.type) {
        storage.removeItem('persist:root');
        const { routing } = state;
        // eslint-disable-next-line no-param-reassign
        state = { locale: state.locale, routing };
    }

    return appReducer(state, action);
};

export default rootReducer;
