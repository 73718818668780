const defaultState = {
    users: [],
};

const usersReducer = (state = defaultState, { type, users }) => {
    switch (type) {
        case 'USERS_LIST_SET':
            return { ...state, users };
        default:
            return state;
    }
};

export default usersReducer;
