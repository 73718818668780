const defaultState = {
    companies: [],
    adminClient: {
        activeUsers: 0,
        maxUsers: 0,
    },
};

const companiesReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'COMPANIES_LIST_SET':
            return { ...state, ...payload };
        case 'CLIENT_ADMIN_SET':
            return { ...state, adminClient: { ...payload } };
        default:
            return state;
    }
};

export default companiesReducer;
