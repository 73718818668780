import { combineReducers } from 'redux';

import app from './app.reducer';
import companies from './companies.reducer';
import documents from './documents.reducer';
import env from './env.reducer';
import signIn from './signIn.reducer';
import upload from './upload.reducer';
import users from './users.reducer';
import videos from './videos.reducer';

const reducer = combineReducers({
    app,
    companies,
    documents,
    env,
    signIn,
    upload,
    users,
    videos,
});

export default reducer;
