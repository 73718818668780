import firebase from 'firebase/compat/app';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import generatePassword from 'password-generator';
import passwordHash from 'password-hash';

export const createGedUser = (companyId, lang, hasGedUser) => {
    const db = firebase.firestore();

    const randomPassword = generatePassword();
    const password = passwordHash.generate(randomPassword);

    return db.collection('companies')
        .doc(companyId)
        .get()
        .then(async (companySnapshot) => {
            const id = companySnapshot.data().installer_code.toLocaleLowerCase();
            const user = db.collection('users').doc();

            if (hasGedUser) await db.collection('users')
                .where('id', '==', hasGedUser.id)
                .get()
                .then((usersSnapshot) => {
                    usersSnapshot.docs[0].ref.delete();
                });

            return user.set({
                company: db.doc(`companies/${companyId}`),
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                first_name: 'ged',
                last_name: 'ged',
                role: 'ged',
                id,
                lang,
                password,
            }).then(() => firebase.functions().httpsCallable('sendGedPassword')({
                id: user.id,
                password: randomPassword,
                lang,
            }));
        });
};

export const createUser = (values, agency) => {
    const db = firebase.firestore();
    const newValues = values;

    if ('installer' === values.role) newValues.userName = values.userNamePhone;
    else newValues.userName = values.userNameMail;

    const companyRef = db.collection('companies').doc(newValues.companyId);

    return db.collection('users')
        .where('agency', '==', newValues.agency || null)
        .where('company', '==', companyRef)
        .get()
        .then((userSnapshot) => {
            if ((!userSnapshot.size && 'adminAgency' === newValues.role) || 'adminAgency' !== newValues.role) return db.collection('users')
                .where('id', '==', newValues.userName).get()
                .then((userSnapshot2) => {
                    if (!userSnapshot2.size) {
                        const user = db.collection('users').doc();

                        const userValues = {
                            agency: newValues.agency || agency || '',
                            company: db.doc(`companies/${newValues.companyId}`),
                            country: newValues.country || '',
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                            first_name: newValues.firstName,
                            id: newValues.userName,
                            lang: newValues.lang,
                            last_name: newValues.lastName,
                            phone: newValues.country ? parsePhoneNumberFromString(newValues.phone, newValues.country.toUpperCase()).number : newValues.phone,
                            role: newValues.role,
                        };

                        const sendPasswordValues = {
                            id: user.id,
                            lang: newValues.lang,
                        };

                        if (newValues.role !== 'installer') {
                            const randomPassword = generatePassword();
                            userValues.password = passwordHash.generate(randomPassword);
                            sendPasswordValues.password = randomPassword;

                            return user.set(userValues).then(() => firebase.functions().httpsCallable('sendPassword')(sendPasswordValues));
                        }

                        return user.set(userValues);
                    }
                    throw new Error('user_already_exist');
                });

            throw new Error('agency_already_exist');
        })
        .catch((e) => {
            throw new Error(e.message);
        });
};

export const deleteUser = async (firebaseId, record) => {
    const db = firebase.firestore();
    const userSnapshot = await db.collection('users').get();

    if ('adminAgency' === record.nativeRole) return new Promise((resolve, reject) => {
        db.collection('users').doc(firebaseId).delete()
            .then(() => {
                if (userSnapshot.size) userSnapshot.forEach((userDoc) => {
                    if (userDoc.data().agency === record.agency) return userDoc.ref.delete().then(() => {
                        resolve(true);
                    })
                        .catch((e) => {
                            reject(e);
                        });

                    return false;
                });
                else resolve(true);
            })
            .catch((e) => {
                reject(e);
            });
    });

    return new Promise((resolve, reject) => {
        db.collection('users').doc(firebaseId).delete()
            .then(() => {
                resolve(true);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const setUsersList = (users) => ({
    type: 'USERS_LIST_SET',
    users,
});

export const getOneUser = (firebaseId) => (dispatch, getState) => {
    const db = firebase.firestore();
    const { users: { users } } = getState();
    db.collection('users')
        .doc(firebaseId)
        .get()
        .then((userSnapshot) => {
            const response = [...users];
            new Promise((resolve) => {
                if (userSnapshot.exists) userSnapshot.data().company.get().then((companySnapshot) => {
                    const datas = {
                        ...userSnapshot.data(),
                        company: companySnapshot.data(),
                        firebaseId: userSnapshot.id,
                    };
                    if (datas.company) datas.company.firebaseId = companySnapshot.id;
                    users.forEach((user, index) => {
                        if (user.firebaseId === userSnapshot.id) response[index] = datas;
                    });
                    resolve(true);
                });
                else {
                    users.forEach((user, index) => {
                        if (user.firebaseId === userSnapshot.id) response.splice(index, 1);
                    });
                    resolve(true);
                }
            }).then(() => {
                dispatch(setUsersList(response));
            });
        });
};

export const getUsers = (text) => async (dispatch) => {
    const querySnapshot = await firebase.firestore().collection('users').get();
    const response = [];

    return new Promise((resolve, reject) => {
        let i = 0;
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (!response.filter((res) => res.firebaseId === data.firebaseId).length
                    && (
                        data.id.includes(text)
                        || (data.phone || '').includes(text)
                        || (`${data.first_name.toLocaleLowerCase()} ${data.last_name.toLocaleLowerCase()}`).includes(text.toLocaleLowerCase())
                    )
            ) data.company.get()
                .then((companySnapshot) => {
                    response.push({
                        ...data,
                        company: {
                            ...companySnapshot.data(),
                            firebaseId: companySnapshot.id,
                        },
                        firebaseId: doc.id,
                    });
                    i++;
                    if (i === querySnapshot.size) if (response.length) {
                        dispatch(setUsersList(response));
                        resolve(true);
                    } else resolve(false);
                })
                .catch((e) => reject(e));
            else {
                i++;
                if (i === querySnapshot.size) if (response.length) {
                    dispatch(setUsersList(response));
                    resolve(true);
                } else resolve(false);
            }
        });
    });
};

const setAdminClient = (data) => ({
    type: 'CLIENT_ADMIN_SET',
    ...data,
});

const setCompaniesList = (companies) => ({
    type: 'COMPANIES_LIST_SET',
    companies,
});

export const getUsersByCompany = (c, role, agency) => (dispatch) => {
    const companies = typeof c === 'object' ? c : [c];
    const db = firebase.firestore();
    const usersList = [];

    companies.forEach((company, index) => {
        const companyRef = db.collection('companies').doc(company);
        db.collection('users')
            .where('company', '==', companyRef)
            .get()
            .then((userSnapshot) => {
                let i = 0;
                let response = [];
                new Promise((resolve) => {
                    userSnapshot.forEach((doc) => {
                        if (doc.data().company) doc.data().company.get().then((companySnapshot) => {
                            response.push({
                                ...doc.data(),
                                company: {
                                    ...companySnapshot.data(),
                                    firebaseId: companySnapshot.id,
                                },
                                firebaseId: doc.id,
                            });
                            i++;
                            if (i === userSnapshot.size) resolve(true);
                        });
                    });
                }).then(() => {
                    if ('adminAgency' === role) response = response.filter((user) => ('installer' === user.role ? user.agency === agency : true));
                    if ('adminClient' === role) response = response.filter((user) => ('installer' === user.role ? undefined === user.agency || '' === user.agency : true));
                    dispatch(setAdminClient({
                        activeUsers: userSnapshot.size,
                        maxUsers: response[0].company.max_users,
                    }));
                    dispatch(setCompaniesList([response[0].company]));
                    usersList.push(...response);
                    if (index === companies.length - 1) dispatch(setUsersList(usersList));
                });
            });
    });
};

export const updateUser = (firebaseId, values) => {
    const db = firebase.firestore();
    const newValues = values;

    Object.keys(values).forEach((key) => (values[key] === undefined ? delete newValues[key] : ''));
    if (values.company) newValues.company = db.doc(`companies/${values.company}`);

    return new Promise((resolve, reject) => {
        db.collection('users').doc(firebaseId).update({
            ...newValues,
        }).then(() => {
            resolve(true);
        })
            .catch((e) => {
                reject(e);
            });
    });
};
