import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import PropTypes from 'prop-types';
import React from 'react';

export const EditableContext = React.createContext();

const EditableRow = ({ form, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

EditableRow.propTypes = {
    form: PropTypes.shape({}).isRequired,
};

export default Form.create()(EditableRow);
