export const ROUTES = [
    {
        name: 'user',
        i18n: 'users_management',
    },
    {
        name: 'company',
        i18n: 'company_management',
    },
    {
        name: 'documents',
        i18n: 'hello_documents_clients',
    },
    {
        name: 'support',
        i18n: 'hello_support_video',
    },
];

export default ROUTES;
