const defaultState = {
    firmwares: { prod: { timestamp: null, version: 0 }, vmi: { timestamp: null, version: 0 } },
};

const firmwareReducer = (state = defaultState, { type, firmwares }) => {
    switch (type) {
        case 'FIRMWARE_SET':
            return { ...state, firmwares };
        default:
            return state;
    }
};

export default firmwareReducer;
