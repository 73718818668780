import firebase from 'firebase/compat/app';

let installerCodeClient = 'Nothing';

let documentsList = [];

document.addEventListener('click', (e) => {
    const href = e.target.closest('a');

    if ((href != null) && ('clientDocuments' === href.className)) {
        e.preventDefault();
        const tmpFileNumber = href.href.split('#')[href.href.split('#').length - 1];

        firebase.functions().httpsCallable('getDocumentContent')({
            tmpFileNumber,
        }).then((response) => {
            const rawC = response.data.doc[0];
            if (rawC !== 'undefined') {
                const win = window.open();
                if (win != null) win.document.write(`${'<iframe src="data:application/pdf;base64,'}${rawC}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`);
            }
        }, (error) => {
            console.error(error);
        });
    }
}, false);

const setClientDocumentsList = (documents) => ({
    type: 'DOCUMENTS_LIST_SET',
    documents,
});

function documentsFillInformations(res, dispatch, filterKey) {
    const response = [];
    for (let i = 0; i < res.length; i++) if (('' === filterKey) || (res[i][0]).toLocaleLowerCase().includes(filterKey.toLocaleLowerCase())) {
        const datas = {
            doc_ref: `${i} - ${res[i][0]}`,
            doc_type_filter: res[i][2],
            doc_link: res[i][1],
            doc_internet_date: res[i][3],
            firebaseId: `${i} - ${res[i][0]}`,
        };
            // datas.doc_link = "https://" + REMOTE_SERVER_NAME + "/Client/Web/Viewer/Viewer.aspx?docno=" + res[i][1] + "&ssoToken=" + THEREFORE_AUTH_SSO_TOKEN
        response.push(datas);
    }

    dispatch(setClientDocumentsList(response));
}

export const setDocumentsAdmin = () => () => {
    installerCodeClient = '*';
};

export const getDocuments = () => (dispatch) => {
    if ('undefined' === typeof localStorage) return;

    if (installerCodeClient !== '*') installerCodeClient = localStorage.getItem('installerCode');

    if ('Nothing' === installerCodeClient) return;

    firebase.functions().httpsCallable('getDocuments')({
        installerCodeClient,
    }).then((result) => {
        documentsList = result.data.documents;
        documentsFillInformations(documentsList, dispatch, '');
    }).catch((e) => {
        console.error(`Inside sendPushNotification error: ${e}`);
    });
};
