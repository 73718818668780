import firebase from 'firebase/compat/app';
import parsePhoneNumberFromString from 'libphonenumber-js';

export const accountCreation = (values) => {
    const account = firebase.functions().httpsCallable('account');

    return new Promise((resolve, reject) => {
        account({
            ...values,
            phone: parsePhoneNumberFromString(values.first_installer_phone || values.phone, values.country.toUpperCase()).number,
        }).then(() => {
            resolve('account_mail');
        }).catch((e) => {
            if (e.code.includes('not-found')) reject(new Error('code_not_found'));
            else if (e.code.includes('functions/unknown')) reject(new Error('sylob'));
            else reject(new Error('already_exist'));
        });
    });
};

export default accountCreation;
