import './FirstConnection.scss';
import { Card, Tabs } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import PageLayout from '../layout/PageLayout';
import HaveAccount from './HaveAccount';
import HaveNoAccount from './HaveNoAccount';

class FirstConnection extends React.Component {
    constructor(props) {
        const urlParams = new URLSearchParams(window.location.search);

        super(props);
        this.changeTab = this.changeTab.bind(this);
        this.state = { tab: urlParams.get('tab') || '1' };
    }

    changeTab(index) {
        this.setState({ tab: index });
    }

    render() {
        const {
            intl,
            history: { goBack, push },
        } = this.props;
        const { tab } = this.state;

        const { TabPane } = Tabs;

        return (
            <PageLayout goBack={goBack} title={intl.formatMessage({ id: 'login_first_connection' })}>
                <div className="first-connection-container">
                    <Card className="first-connection-card">
                        <Tabs activeKey={tab} onChange={this.changeTab} type="card">
                            <TabPane key="1" tab={intl.formatMessage({ id: 'login_first_connection_account_1' })}>
                                <HaveAccount push={push} />
                            </TabPane>
                            <TabPane key="2" tab={intl.formatMessage({ id: 'login_first_connection_account_2' })}>
                                <HaveNoAccount push={push} />
                            </TabPane>
                        </Tabs>
                    </Card>
                </div>
            </PageLayout>
        );
    }
}

FirstConnection.propTypes = {
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
};

export default injectIntl(FirstConnection);
