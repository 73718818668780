const defaultState = {
    agency: null,
    company: '',
    id: null,
    loggedIn: false,
    role: null,
};

const signInReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'LOGIN_SET':
            return { ...state, ...payload };
        default:
            return state;
    }
};

export default signInReducer;
