import { Form } from '@ant-design/compatible';
import { UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { forgotPassword } from '../../actions/signIn.actions';
import validationRules from '../../utils/validationRules';
import PageLayout from '../layout/PageLayout';

const { Item: FormItem } = Form;

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading: false,
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
            form: { validateFields },
            intl,
            history: { push },
        } = this.props;
        validateFields((err, { userName }) => {
            if (!err) {
                this.setState({ loading: true });
                forgotPassword({
                    lang: intl.locale,
                    userName,
                }).then((result) => {
                    message.success(intl.formatMessage({ id: `message_success_${result}` }));
                    setTimeout(() => push('/'));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
    }

    render() {
        const { form: { getFieldDecorator }, history: { goBack }, intl } = this.props;
        const { loading } = this.state;

        return (
            <PageLayout goBack={goBack} title={intl.formatMessage({ id: 'login_forgot_password' })}>
                <div className="signin-container">
                    <Card className="signin-card">
                        <Form className="login-form" onSubmit={this.handleSubmit}>
                            <FormItem label={intl.formatMessage({ id: 'login_username' })}>
                                {getFieldDecorator('userName', {
                                    rules: validationRules.username,
                                })(
                                    <Input
                                        placeholder={intl.formatMessage({ id: 'login_username' })}
                                        prefix={<UserOutlined />}
                                    />,
                                )}
                            </FormItem>
                            <FormItem>
                                <Button className="login-form-button" htmlType="submit" loading={loading} type="primary" block>
                                    {intl.formatMessage({ id: 'forgotPassword_forgotBtn' })}
                                </Button>
                            </FormItem>
                        </Form>
                    </Card>
                </div>
            </PageLayout>
        );
    }
}

ForgotPassword.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
};

const ForgotPasswordForm = Form.create({ name: 'forgot-password' })(ForgotPassword);
const mapStateToProps = ({ signIn: { userName } }) => ({ userName });

export default connect(mapStateToProps)(injectIntl(Form.create({ name: 'forgot_password' })(ForgotPasswordForm)));
