import { Form } from '@ant-design/compatible';
import { LockOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changePassword } from '../../actions/signIn.actions';
import validationRules from '../../utils/validationRules';
import PageLayout from '../layout/PageLayout';

const { Item: FormItem } = Form;

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading: false,
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
            form: { validateFields },
            id,
            history: { push },
        } = this.props;
        validateFields((err, values) => {
            if (!err) {
                const { intl } = this.props;
                this.setState({ loading: true });
                changePassword({ id, ...values }).then((result) => {
                    message.success(intl.formatMessage({ id: `message_success_${result}` }));
                    setTimeout(() => push('/hello'));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
    }

    render() {
        const {
            intl,
            form: { getFieldDecorator },
            history: { goBack },
        } = this.props;
        const { loading } = this.state;

        return (
            <PageLayout goBack={goBack} title={intl.formatMessage({ id: 'menu_change_password' })}>
                <div className="signin-container">
                    <Card className="signin-card">
                        <Form className="login-form" onSubmit={this.handleSubmit}>
                            <FormItem label={intl.formatMessage({ id: 'changePassword_currentPassword' })}>
                                {getFieldDecorator('currentPassword', {
                                    rules: validationRules.password,
                                })(
                                    <Input.Password
                                        placeholder={intl.formatMessage({ id: 'changePassword_currentPassword' })}
                                        prefix={<LockOutlined />}
                                        type="password"
                                    />,
                                )}
                            </FormItem>
                            <FormItem label={intl.formatMessage({ id: 'changePassword_newPassword' })}>
                                {getFieldDecorator('newPassword', {
                                    rules: validationRules.password,
                                })(
                                    <Input.Password
                                        placeholder={intl.formatMessage({ id: 'changePassword_newPassword' })}
                                        prefix={<LockOutlined />}
                                        type="password"
                                    />,
                                )}
                            </FormItem>
                            <FormItem>
                                <Button className="login-form-button" htmlType="submit" loading={loading} type="primary" block>
                                    {intl.formatMessage({ id: 'changePassword_changeBtn' })}
                                </Button>
                            </FormItem>
                        </Form>
                    </Card>
                </div>
            </PageLayout>
        );
    }
}

ChangePassword.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    id: PropTypes.string,
    intl: PropTypes.any.isRequired,
};

const ChangePasswordForm = Form.create({ name: 'change-password' })(ChangePassword);
const mapStateToProps = ({ signIn: { id, userName } }) => ({ id, userName });
const mapDispatchToProps = { changePassword };

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangePasswordForm));
