import { Form } from '@ant-design/compatible';
import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Alert, Button, Input, message, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accountCreation } from '../../actions/account.actions';
import { inputToLowerCase } from '../../utils/inputLowerCase';
import validationRules from '../../utils/validationRules';
import { COUNTRIES_TABLE } from '../user/countries';

const { Item: FormItem } = Form;
const { Option } = Select;

class HaveAccount extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading: false,
        };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
            form: { validateFields },
            intl,
            push,
        } = this.props;
        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const values2 = values;
                values2.lang = intl.locale;
                accountCreation(values2).then((result) => {
                    message.success(intl.formatMessage({ id: `message_success_${result}` }));
                    setTimeout(() => push('/'));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
    }

    render() {
        const {
            form: { getFieldDecorator },
            intl,
        } = this.props;
        const { loading } = this.state;

        return (
            <>
                <Alert message={<FormattedMessage id="first_connection_info" />} type="info" showIcon />
                <Form className="login-form" onSubmit={this.handleSubmit}>
                    <FormItem>
                        {getFieldDecorator('id', {
                            rules: validationRules.clientAccount,
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'login_first_connection_account' })}
                                prefix={<UserOutlined />}
                            />,
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('admin_email', {
                            rules: validationRules.mail,
                        })(
                            <Input
                                onChange={inputToLowerCase}
                                placeholder={intl.formatMessage({ id: 'admin_email' })}
                                prefix={<MailOutlined />}
                            />,
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('country', {
                            rules: validationRules.country,
                        })(
                            <Select placeholder={intl.formatMessage({ id: 'user_country' })}>
                                {COUNTRIES_TABLE.sort((a, b) => intl.formatMessage({ id: `country_${b.prefix}` }) < intl.formatMessage({ id: `country_${a.prefix}` })).map((country) => <Option key={country.prefix}><img alt={country.prefix} src={`https://cdn.countryflags.com/thumbs/${country.fullName}/flag-3d-500.png`} /> <FormattedMessage id={`country_${country.prefix}`} /></Option>) }
                            </Select>,
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('first_installer_phone', {
                            rules: validationRules.phone,
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'login_first_connection_installer' })}
                                prefix={<PhoneOutlined />}
                            />,
                        )}
                    </FormItem>
                    <FormItem>
                        <Button className="responsive" htmlType="submit" loading={loading} type="primary" block>
                            <FormattedMessage id="login_first_connection_account_create" />
                        </Button>
                    </FormItem>
                </Form>
            </>
        );
    }
}

HaveAccount.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    push: PropTypes.func.isRequired,
};

export default connect()(injectIntl(Form.create({ name: 'have_account' })(HaveAccount)));
