const defaultState = {
    documents: [],
};

const documentsReducer = (state = defaultState, { type, documents }) => {
    switch (type) {
        case 'DOCUMENTS_LIST_SET':
            return { ...state, documents };
        default:
            return state;
    }
};

export default documentsReducer;
