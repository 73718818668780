import './UsersFormItems.scss';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import validationRules from '../../utils/validationRules';
import { COUNTRIES_TABLE } from './countries';
import { ROLES_TABLE } from './roles';

const { Option } = Select;
const { Item: FormItem } = Form;

class UsersFormItems extends Component {
    constructor(props) {
        super(props);

        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.state = { selectedRole: '' };
    }

    handleSelectChange(value) {
        const { setDrawerAction } = this.props;
        setDrawerAction(true);
        this.setState({ selectedRole: value });
    }

    handlePhoneChange(e) {
        const { form } = this.props;
        const { value } = e.target;
        form.setFieldsValue({
            phone: value,
        });

        return value;
    }

    render() {
        const { companies, form: { getFieldDecorator }, intl, role, hasSelectedUserRole } = this.props;

        const optionsCompanies = companies.sort((a, b) => a.name.localeCompare(b.name)).map((company) => <Option key={company.firebaseId}>{company.name}</Option>);
        const roleLvl = ROLES_TABLE.filter((cRole) => cRole.name === role)[0].level;
        const { selectedRole } = this.state;
        const companiesOptions = { rules: validationRules.company };
        if (1 === companies.length) companiesOptions.initialValue = companies[0].firebaseId;

        return (
            <>
                <FormItem>
                    {getFieldDecorator('role', {
                        rules: validationRules.role,
                    })(
                        <Select onChange={this.handleSelectChange} placeholder={intl.formatMessage({ id: 'user_role' })}>
                            { ROLES_TABLE.filter((cRole) => cRole.level < roleLvl).map((cRole) => <Option key={cRole.name}><FormattedMessage id={`roles_${cRole.name}`} /></Option>) }
                        </Select>,
                    )}
                </FormItem>
                {hasSelectedUserRole && (
                    <>
                        {selectedRole !== 'installer' ? (
                            <FormItem>
                                {getFieldDecorator('userNameMail', {
                                    rules: validationRules.mail,
                                })(
                                    <Input
                                        placeholder={intl.formatMessage({ id: 'user_userName_mail' })}
                                    />,
                                )}
                            </FormItem>
                        ) : (
                            <FormItem>
                                {getFieldDecorator('userNamePhone', {
                                    rules: validationRules.phone,
                                })(
                                    <Input
                                        onChange={this.handlePhoneChange}
                                        placeholder={intl.formatMessage({ id: 'user_userName_phone' })}
                                    />,
                                )}
                            </FormItem>
                        )}
                        <FormItem>
                            {getFieldDecorator('firstName', {
                                rules: validationRules.firstName,
                            })(
                                <Input
                                    placeholder={intl.formatMessage({ id: 'user_firstName' })}
                                />,
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('lastName', {
                                rules: validationRules.lastName,
                            })(
                                <Input
                                    placeholder={intl.formatMessage({ id: 'user_lastName' })}
                                />,
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('country', {
                                rules: validationRules.country,
                            })(
                                <Select placeholder={intl.formatMessage({ id: 'user_country' })}>
                                    {COUNTRIES_TABLE.sort((a, b) => intl.formatMessage({ id: `country_${b.prefix}` }) < intl.formatMessage({ id: `country_${a.prefix}` })).map((country) => <Option key={country.prefix}><img alt={country.prefix} src={`https://cdn.countryflags.com/thumbs/${country.fullName}/flag-3d-500.png`} /> <FormattedMessage id={`country_${country.prefix}`} /></Option>) }
                                </Select>,
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('phone', {
                                rules: validationRules.phone,
                            })(
                                <Input
                                    placeholder={intl.formatMessage({ id: 'user_phone' })}
                                />,
                            )}
                        </FormItem>
                        <FormItem className={'adminAgency' === selectedRole ? '' : 'padding-bottom'}>
                            {getFieldDecorator('companyId', companiesOptions)(
                                <Select
                                    placeholder={intl.formatMessage({ id: 'user_companyId' })}
                                >
                                    {optionsCompanies}
                                </Select>,
                            )}
                        </FormItem>
                        {'adminAgency' === selectedRole && (
                            <FormItem className="padding-bottom">
                                {getFieldDecorator('agency', {
                                    rules: validationRules.agency,
                                })(
                                    <Input
                                        placeholder={intl.formatMessage({ id: 'agency' })}
                                    />,
                                )}
                            </FormItem>
                        )}
                    </>
                )}
            </>
        );
    }
}

UsersFormItems.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.shape({
        firebaseId: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        setFieldsValue: PropTypes.func.isRequired,
    }).isRequired,
    hasSelectedUserRole: PropTypes.bool,
    intl: PropTypes.any.isRequired,
    role: PropTypes.string.isRequired,
    setDrawerAction: PropTypes.func,
};

const UserForm = Form.create({ name: 'user-form' })(UsersFormItems);

export default connect()(injectIntl(Form.create({ name: 'create_user' })(UserForm)));
