import firebase from 'firebase/compat/app';
import { createUser } from './user.actions';

export const createCompany = async (values) => {
    const ref = firebase.firestore().collection('companies');

    const query1 = await ref.where('siren', '==', values.siren).get();
    const query2 = await ref.where('name', '==', values.name).get();
    const query3 = await ref.where('installer_code', '==', values.installer_code).get();

    if (!(query1.size + query2.size + query3.size)) {
        const companyDoc = ref.doc();

        const values2 = values;
        values2.name = values.name.trim();

        return companyDoc.set(values2).then(() => createUser({
            companyId: companyDoc.id,
            firstName: 'admin',
            lang: values.lang,
            lastName: 'admin',
            phone: values.phone,
            role: 'adminClient',
            siren: values.siren,
            userNameMail: values.mail,
        }));
    }

    throw new Error('company_already_exist');
};

export const updateCompany = (firebaseId, values) => {
    const db = firebase.firestore();

    const newValues = values;
    Object.keys(values).forEach((key) => (values[key] === undefined ? delete newValues[key] : ''));

    return new Promise((resolve, reject) => {
        db.collection('companies')
            .doc(firebaseId)
            .update({
                ...newValues,
            }).then(() => {
                resolve(true);
            })
            .catch((e) => {
                reject(e);
            });
    });
};

const setCompaniesList = (companies) => ({
    type: 'COMPANIES_LIST_SET',
    companies,
});

export const getCompanies = (companies) => async (dispatch) => {
    const db = firebase.firestore();
    const querySnapshot = await db.collection('companies').get();
    const response = [];
    if (companies) dispatch(setCompaniesList(companies));
    else {
        querySnapshot.forEach(async (doc) => {
            const datas = doc.data();
            const datasToPush = {
                ...datas,
                firebaseId: doc.id,
            };
            response.push(datasToPush);
        });
        dispatch(setCompaniesList(response));
    }
};

export const getCompany = (companyId) => (dispatch) => {
    const db = firebase.firestore();
    db.collection('companies')
        .doc(companyId)
        .get()
        .then(async (compagnySnapshot) => {
            const data = {
                ...compagnySnapshot.data(),
                firebaseId: compagnySnapshot.id,
            };
            dispatch(setCompaniesList([data]));
        });
};

export const deleteCompany = async (firebaseId) => {
    const db = firebase.firestore();
    const userSnapshot = await db.collection('users').get();

    return new Promise((resolve, reject) => {
        db.collection('companies').doc(firebaseId).delete()
            .then(() => {
                let i = 0;
                if (userSnapshot.size) userSnapshot.forEach((userDoc) => {
                    i++;
                    if (userDoc.data().company) userDoc.data().company.get().then((companySnapshot) => {
                        if (companySnapshot.id === firebaseId) userDoc.ref.delete().catch((e) => reject(e));
                        if (i === userSnapshot.size) resolve(true);
                    }).catch((e) => reject(e));
                });
                else resolve(true);
            })
            .catch((e) => reject(e));
    });
};
