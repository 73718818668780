import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getDocuments } from '../../actions/documents.actions';
import EditableTable from '../table/EditableTable';

const tableName = 'document_client';

const DocumentsTable = ({ getDocuments: getDocumentsAction, documents }) => {
    const alreadyPushed = [];
    const companiesArray = [];

    documents.forEach((clientDocument) => {
        if (!alreadyPushed.includes(clientDocument.doc_ref)) {
            alreadyPushed.push(clientDocument.doc_ref);
            companiesArray.push({
                text: clientDocument.doc_ref,
                value: clientDocument.doc_link,
            });
        }
    });

    const columns = [
        {
            key: 'doc_name',
            dataIndex: 'doc_ref',
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.doc_ref.toString().localeCompare(b.doc_ref),
            title: <FormattedMessage id={`${tableName}_doc_ref`} />,
            type: 'text',
            width: 200,
        },
        {
            key: 'doc_type',
            dataIndex: 'doc_type_filter',
            editable: true,
            ellipsis: true,
            filterable: true,
            filterMultiple: false,
            filters: [
                {
                    text: <FormattedMessage id="document_client_preuve_de_commande" />,
                    value: 'Preuve_de_commande',
                },
                {
                    text: <FormattedMessage id="document_client_option_de_commande" />,
                    value: 'Option_de_commande',
                },
                {
                    text: <FormattedMessage id="document_client_bordereaux_de_livraison" />,
                    value: 'Bordereaux_de_livraison',
                },
                {
                    text: <FormattedMessage id="document_client_facture_client" />,
                    value: 'Facture_Client',
                },
            ],
            onFilter: (value, record) => record.doc_type_filter === value.replace(/_/gi, ' '),
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.doc_type_filter.toString().localeCompare(b.doc_type_filter),
            title: <FormattedMessage id={`${tableName}_doc_type`} />,
            type: 'text',
            width: 200,
        },
        {
            key: 'doc_internet_link',
            dataIndex: 'doc_link',
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            title: <FormattedMessage id={`${tableName}_doc_link`} />,
            render: (text) => <a className="clientDocuments" href={`#${text}`}>Visualiser</a>,
            type: 'text',
            width: 250,
        },
        {
            key: 'doc_internet_creation_date',
            dataIndex: 'doc_internet_date',
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.doc_internet_date.localeCompare(b.doc_internet_date),
            title: <FormattedMessage id={`${tableName}_doc_creation_date`} />,
            type: 'text',
        },
    ];

    const messages = {
        deleteMessage: 'deleteUser_message',
        deleteSuccess: 'message_success_delete_user',
        updateSuccess: 'message_success_update_user',
    };

    return (
        <EditableTable
            columns={columns}
            dataSource={documents}
            deleteFunction={getDocumentsAction}
            editableContent={false}
            messages={messages}
            refresh={getDocumentsAction}
            tableName={tableName}
            updateFunction={getDocumentsAction}
        />
    );
};

DocumentsTable.propTypes = {
    documents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    getDocuments: PropTypes.func.isRequired,
};

const mapStateToProps = ({ documents: { documents } }) => ({ documents });
export default connect(mapStateToProps, { getDocuments })(DocumentsTable);
