import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { Button, Input, message, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { accountCreation } from '../../actions/account.actions';
import validationRules from '../../utils/validationRules';
import { COUNTRIES_TABLE } from '../user/countries';

const { Item: FormItem } = Form;
const { Option } = Select;

class HaveNoAccount extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { loading: false };
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
            form: { validateFields },
            intl,
            push,
        } = this.props;
        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const values2 = {
                    ...values,
                    lang: intl.locale,
                };
                values2.name = values2.name.trim();
                accountCreation(values2).then(() => {
                    message.success(intl.formatMessage({ id: 'message_success_account_installer' }));
                    setTimeout(() => push('/'));
                }).catch((error) => {
                    const translateString = `message_fail_${error.message}`;
                    if (error.message === 'sylob') message.error(intl.formatMessage({ id: translateString }), 12);
                    else if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                    else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                }).finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
    }

    render() {
        const { form: { getFieldDecorator }, intl } = this.props;
        const { loading } = this.state;

        return (
            <Form onSubmit={this.handleSubmit}>
                <FormItem>
                    {getFieldDecorator('name', {
                        rules: validationRules.name,
                    })(
                        <Input
                            placeholder={intl.formatMessage({ id: 'company_name' })}
                        />,
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('admin_email', {
                        rules: validationRules.mail,
                    })(
                        <Input
                            placeholder={intl.formatMessage({ id: 'company_mail' })}
                        />,
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('siren', {
                        rules: validationRules.siren,
                    })(
                        <Input
                            placeholder={intl.formatMessage({ id: 'siren' })}
                        />,
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('distributor', {
                        rules: validationRules.distributor,
                    })(
                        <Input
                            placeholder={intl.formatMessage({ id: 'distributor' })}
                        />,
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('country', {
                        rules: validationRules.country,
                    })(
                        <Select placeholder={intl.formatMessage({ id: 'user_country' })}>
                            {COUNTRIES_TABLE.sort((a, b) => intl.formatMessage({ id: `country_${b.prefix}` }) < intl.formatMessage({ id: `country_${a.prefix}` })).map((country) => <Option key={country.prefix}><img alt={country.prefix} src={`https://cdn.countryflags.com/thumbs/${country.fullName}/flag-3d-500.png`} /> <FormattedMessage id={`country_${country.prefix}`} /></Option>) }
                        </Select>,
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('address', {
                        rules: validationRules.address,
                    })(
                        <Input
                            placeholder={intl.formatMessage({ id: 'company_address' })}
                        />,
                    )}
                </FormItem>
                <div className="half">
                    <FormItem>
                        {getFieldDecorator('city', {
                            rules: validationRules.city,
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'company_city' })}
                            />,
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('zip_code', {
                            rules: validationRules.zip_code,
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'company_zip_code' })}
                            />,
                        )}
                    </FormItem>
                </div>
                <div className="half">
                    <FormItem>
                        {getFieldDecorator('firstName', {
                            rules: validationRules.firstName,
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'user_firstName' })}
                            />,
                        )}
                    </FormItem>
                    <FormItem>
                        {getFieldDecorator('lastName', {
                            rules: validationRules.lastName,
                        })(
                            <Input
                                placeholder={intl.formatMessage({ id: 'user_lastName' })}
                            />,
                        )}
                    </FormItem>
                </div>
                <FormItem>
                    {getFieldDecorator('phone', {
                        rules: validationRules.phone,
                    })(
                        <Input
                            placeholder={intl.formatMessage({ id: 'company_phone' })}
                        />,
                    )}
                </FormItem>
                <FormItem>
                    <Button className="responsive" htmlType="submit" loading={loading} type="primary" block>
                        {intl.formatMessage({ id: 'login_first_connection_account_installer' })}
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

HaveNoAccount.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    push: PropTypes.func.isRequired,
};

export default connect()(injectIntl(Form.create({ name: 'have_account' })(HaveNoAccount)));
