import firebase from 'firebase/compat/app';
import passwordHash from 'password-hash';

import { updateUser } from './user.actions';

export const setUser = (user) => (dispatch) => {
    dispatch({
        agency: user.agency,
        company: user.companyId,
        id: user.id,
        role: user.role,
        type: 'LOGIN_SET',
        userName: user.userName,
    });
};

export const logout = (push) => (dispatch) => {
    firebase.auth().signOut().then(() => {
        dispatch({ type: 'DESTROY_SESSION' });
        setTimeout(() => {
            push('/');
            dispatch({
                agency: null,
                company: null,
                id: null,
                role: null,
                type: 'LOGIN_SET',
                userName: null,
            });
        });
    });
};

export const changePassword = (values) => {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
        db.collection('users').doc(values.id).get().then((querySnapshot) => {
            if (passwordHash.verify(values.currentPassword, querySnapshot.data().password)) updateUser(values.id, {
                password: passwordHash.generate(values.newPassword),
            }).then(() => {
                resolve('change_password');
            }).catch((e) => {
                reject(e);
            });
            else reject(new Error('wrong_password'));
        })
            .catch((e) => {
                reject(e);
            });
    });
};

export const dispatchForgotPasswordRequestSent = () => ({
    type: 'FORGOT_PASSWORD_REQUEST_SENT',
});

export const forgotPassword = (values) => new Promise((resolve, reject) => {
    firebase.functions().httpsCallable('forgotPassword')({
        id: values.userName,
        lang: values.lang,
    }).then(() => {
        dispatchForgotPasswordRequestSent();
        resolve('forgot_password');
    }).catch((e) => {
        reject(e);
    });
});
