import './UploadFirmware.scss';
import { Form } from '@ant-design/compatible';
import { UploadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Button, Card, Input, Upload, message } from 'antd';
import firebase from 'firebase/compat/app';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { checkFirmware, uploadFirmware } from '../../actions/upload.actions';
import validationRules from '../../utils/validationRules';
import PageLayout from '../layout/PageLayout';

const { Item: FormItem } = Form;

class UploadFirmware extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                prod: false,
                vmi: false,
            },
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const remoteConfig = firebase.remoteConfig();
        const { checkFirmware: checkFirmwareAction } = this.props;
        const { history: { push } } = this.props;
        remoteConfig.fetchAndActivate();

        if (remoteConfig.getString('fw_password') !== localStorage.getItem('fw_password')) push('.');
        else checkFirmwareAction();
    }

    handleChange(app, file) {
        const {
            checkFirmware: checkFirmwareAction,
            form: { getFieldError, getFieldValue },
            intl,
        } = this.props;

        if (!getFieldError(app)) {
            this.setState({ loading: { [app]: true } });
            uploadFirmware(app, file, getFieldValue(app)).then(() => {
                message.success('Firmware uploadé avec succès');
                checkFirmwareAction();
            }).catch((error) => {
                const translateString = `message_fail_${error.message}`;
                if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
            }).finally(() => {
                this.setState({ loading: { [app]: false } });
            });
        }

        return false;
    }

    render() {
        const {
            firmwares,
            form: { getFieldDecorator },
            history: { goBack },
        } = this.props;
        const { loading } = this.state;

        const prodDate = new Date((firmwares.prod.timestamp || {}).seconds * 1000).toLocaleDateString('fr', { hour: 'numeric', minute: 'numeric' });
        const vmiDate = new Date((firmwares.vmi.timestamp || {}).seconds * 1000).toLocaleDateString('fr', { hour: 'numeric', minute: 'numeric' });

        return (
            <PageLayout goBack={goBack} title="Charger le logiciel">

                <div className="upload-container">

                    <Card title="App Production">
                        <p>Version actuelle : <strong>v{firmwares.prod.version}</strong></p>
                        <p>Date d&apos;upload : <strong>{prodDate}</strong></p>
                        <FormItem>
                            {getFieldDecorator('prod', {
                                rules: validationRules.firmware,
                            })(
                                <Input
                                    name="prod"
                                    placeholder="Version du nouveau firmware"
                                    type="text"
                                />,
                            )}
                        </FormItem>
                        <Upload
                            accept="application/octet-stream,application/macbinary"
                            beforeUpload={(file) => this.handleChange('prod', file)}
                            showUploadList={false}
                        >
                            <Button className="upload-btn" loading={loading.prod}>Charger<UploadOutlined /></Button>
                        </Upload>
                    </Card>

                    <Card title="VMI+">
                        <p>Version actuelle : <strong>v{firmwares.vmi.version}</strong></p>
                        <p>Date d&apos;upload : <strong>{vmiDate}</strong></p>
                        <FormItem>
                            {getFieldDecorator('vmi', {
                                rules: validationRules.firmware,
                            })(
                                <Input
                                    placeholder="Version du nouveau firmware"
                                    type="text"
                                />,
                            )}
                        </FormItem>
                        <Upload
                            accept="application/octet-stream,application/macbinary"
                            beforeUpload={(file) => this.handleChange('vmi', file)}
                            showUploadList={false}
                        >
                            <Button className="upload-btn" loading={loading.vmi}>Charger<UploadOutlined /></Button>
                        </Upload>
                    </Card>

                </div>

            </PageLayout>
        );
    }
}

UploadFirmware.propTypes = {
    checkFirmware: PropTypes.func.isRequired,
    firmwares: PropTypes.object.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        getFieldError: PropTypes.func.isRequired,
        getFieldValue: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = ({ upload: { firmwares } }) => ({ firmwares });
export default connect(mapStateToProps, { checkFirmware })(injectIntl(Form.create({ name: 'firmware_upload' })(UploadFirmware)));
