import firebase from 'firebase/compat/app';

export const checkFirmware = () => async (dispatch) => {
    const db = firebase.firestore();
    const querySnapshot = await db.collection('firmwares').orderBy('timestamp', 'desc').get();
    const querySnapshotProd = await db.collection('firmwares_prod').orderBy('timestamp', 'desc').get();
    dispatch({
        firmwares: {
            prod: { timestamp: querySnapshotProd.docs[0].data().timestamp, version: querySnapshotProd.docs[0].data().version },
            vmi: { timestamp: querySnapshot.docs[0].data().timestamp, version: querySnapshot.docs[0].data().version },
        },
        type: 'FIRMWARE_SET',
    });
};

export const uploadFirmware = (app, file, version) => {
    const db = firebase.firestore();
    const storageRef = firebase.storage().ref().child(`firmwares/${file.name}`);

    return new Promise((resolve, reject) => {
        db.collection(app === 'vmi' ? 'firmwares' : 'firmwares_prod').doc().set({
            file_name: file.name,
            timestamp: new Date(),
            version,
        }).then(() => {
            setTimeout(() => storageRef.put(file).then(() => {
                resolve('upload_firmware');
            }).catch((e) => {
                reject(e);
            }));
        })
            .catch((e) => {
                reject(e);
            });
    });
};

export default uploadFirmware;
