export const COUNTRIES_TABLE = [
    {
        fullName: 'andorra',
        prefix: 'ad',
    },
    {
        fullName: 'albania',
        prefix: 'al',
    },
    {
        fullName: 'austria',
        prefix: 'at',
    },
    {
        fullName: 'australia',
        prefix: 'au',
    },
    {
        fullName: 'bosnia-and-herzegovina',
        prefix: 'ba',
    },
    {
        fullName: 'belgium',
        prefix: 'be',
    },
    {
        fullName: 'bulgaria',
        prefix: 'bg',
    },
    {
        fullName: 'belarus',
        prefix: 'by',
    },
    {
        fullName: 'switzerland',
        prefix: 'ch',
    },
    {
        fullName: 'cyprus',
        prefix: 'cy',
    },
    {
        fullName: 'czech-republic',
        prefix: 'cz',
    },
    {
        fullName: 'germany',
        prefix: 'de',
    },
    {
        fullName: 'denmark',
        prefix: 'dk',
    },
    {
        fullName: 'estonia',
        prefix: 'ee',
    },
    {
        fullName: 'spain',
        prefix: 'es',
    },
    {
        fullName: 'finland',
        prefix: 'fi',
    },
    {
        fullName: 'france',
        prefix: 'fr',
    },
    {
        fullName: 'united-kingdom',
        prefix: 'gb',
    },
    {
        fullName: 'greece',
        prefix: 'gr',
    },
    {
        fullName: 'croatia',
        prefix: 'hr',
    },
    {
        fullName: 'hungary',
        prefix: 'hu',
    },
    {
        fullName: 'ireland',
        prefix: 'ie',
    },
    {
        fullName: 'israel',
        prefix: 'il',
    },
    {
        fullName: 'iceland',
        prefix: 'is',
    },
    {
        fullName: 'italy',
        prefix: 'it',
    },
    {
        fullName: 'liechtenstein',
        prefix: 'li',
    },
    {
        fullName: 'lithuania',
        prefix: 'lt',
    },
    {
        fullName: 'luxembourg',
        prefix: 'lu',
    },
    {
        fullName: 'latvia',
        prefix: 'lv',
    },
    {
        fullName: 'morocco',
        prefix: 'ma',
    },
    {
        fullName: 'monaco',
        prefix: 'mc',
    },
    {
        fullName: 'moldova',
        prefix: 'md',
    },
    {
        fullName: 'montenegro',
        prefix: 'me',
    },
    {
        fullName: 'north-macedonia',
        prefix: 'mk',
    },
    {
        fullName: 'malta',
        prefix: 'mt',
    },
    {
        fullName: 'netherlands',
        prefix: 'nl',
    },
    {
        fullName: 'norway',
        prefix: 'no',
    },
    {
        fullName: 'new-zealand',
        prefix: 'nz',
    },
    {
        fullName: 'poland',
        prefix: 'pl',
    },
    {
        fullName: 'portugal',
        prefix: 'pt',
    },
    {
        fullName: 'france',
        prefix: 're',
    },
    {
        fullName: 'romania',
        prefix: 'ro',
    },
    {
        fullName: 'serbia',
        prefix: 'rs',
    },
    {
        fullName: 'russia',
        prefix: 'ru',
    },
    {
        fullName: 'sweden',
        prefix: 'se',
    },
    {
        fullName: 'slovenia',
        prefix: 'si',
    },
    {
        fullName: 'san-marino',
        prefix: 'sm',
    },
    {
        fullName: 'slovakia',
        prefix: 'sk',
    },
    {
        fullName: 'turkey',
        prefix: 'tr',
    },
    {
        fullName: 'ukraine',
        prefix: 'ua',
    },
];

export default COUNTRIES_TABLE;
