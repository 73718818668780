import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ROLES_TABLE } from '../components/user/roles';

const mapStateToProps = ({ signIn: { role } }) => ({ role });

export default (ComposedComponent, route) => {
    const requireAuth = (props) => {
        const { role, history: { push } } = props;
        if (!JSON.parse(localStorage.getItem('isLoggin'))) push('/');

        if (role && route) if (!ROLES_TABLE.filter((cRole) => cRole.name === role)[0].hoc.includes(route)) push('/');

        return <ComposedComponent {...props} />;
    };

    requireAuth.propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        role: PropTypes.string,
    };

    return connect(mapStateToProps)(requireAuth);
};
