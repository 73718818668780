import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { deleteCompany, getCompanies, getCompany, updateCompany } from '../../actions/company.actions';
import EditableTable from '../table/EditableTable';

const tableName = 'company';

const messages = {
    deleteMessage: 'deleteCompany_message',
    deleteMessageExtra: 'deleteCompany_message_extra',
    deleteSuccess: 'message_success_delete_company',
    updateSuccess: 'message_success_update_company',
};

const CompanyTable = ({ getCompanies: getCompaniesAction, getCompany: getCompanyAction, companies, role }) => {
    const columns = [
        {
            dataIndex: 'name',
            editable: true,
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.name.localeCompare(b.name),
            title: <FormattedMessage id={`${tableName}_name`} />,
            type: 'text',
        },
        {
            dataIndex: 'siren',
            editable: true,
            ellipsis: true,
            title: <FormattedMessage id="siren" />,
            type: 'text',
            width: 134,
        },
        {
            dataIndex: 'installer_code',
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_installerCode`} />,
            type: 'text',
            width: 140,
        },
        {
            dataIndex: 'address',
            editable: true,
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_address`} />,
            type: 'text',
        },
        {
            dataIndex: 'city',
            editable: true,
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => b.name.localeCompare(a.name),
            title: <FormattedMessage id={`${tableName}_city`} />,
            type: 'text',
            width: 180,
        },
        {
            dataIndex: 'zip_code',
            editable: true,
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.name.localeCompare(b.name),
            title: <FormattedMessage id={`${tableName}_zip_code`} />,
            type: 'text',
            width: 146,
        },
        {
            dataIndex: 'phone',
            editable: true,
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_phone`} />,
            type: 'text',
            width: 146,
        },
        {
            dataIndex: 'mail',
            editable: true,
            ellipsis: true,
            sortDirections: ['ascend', 'descend'],
            sorter: (a, b) => a.mail.localeCompare(b.mail),
            title: <FormattedMessage id={`${tableName}_mail`} />,
            type: 'text',
        },
        {
            dataIndex: 'max_users',
            editable: 'admin' === role,
            ellipsis: true,
            title: <FormattedMessage id={`${tableName}_users`} />,
            type: 'number',
            width: 128,
        },
    ];

    return (
        <EditableTable
            columns={columns}
            dataSource={companies}
            deleteFunction={deleteCompany}
            messages={messages}
            refreshFunction={'admin' === role ? getCompaniesAction : getCompanyAction}
            tableName={tableName}
            updateFunction={updateCompany}
        />
    );
};

CompanyTable.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    getCompanies: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
};

const mapStateToProps = ({ companies: { companies }, signIn: { role } }) => ({ companies, role });
export default connect(mapStateToProps, { getCompanies, getCompany })(CompanyTable);
