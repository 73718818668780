import firebase from 'firebase/compat/app';
import { ROLES_TABLE } from '../components/user/roles';

export const login = (values, setUser) => {
    const auth = firebase.functions().httpsCallable('auth');

    return new Promise((resolve, reject) => {
        auth({
            ...values,
            from: 'portail',
        }).then((result) => {
            if (ROLES_TABLE.map((cRole) => cRole.name).includes(result.data.role))firebase.auth().signInWithCustomToken(result.data.token).then(() => {
                resolve('login');

                if (typeof localStorage !== 'undefined') localStorage.setItem('installerCode', result.data.installerCode);
                setUser(result.data);
            }).catch((e) => {
                reject(e);
            });
            else reject(new Error('standard_account'));
        }).catch((e) => {
            reject(e);
        });
    });
};

export default login;
