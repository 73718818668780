import './404.scss';
import PageLayout from '../layout/PageLayout';

const NotFound = () => (
    <PageLayout title="404">
        <h1 className="notFound">Not found</h1>
    </PageLayout>
);

export default NotFound;
