import './style.scss';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Router from './Router';
import { store, persistor } from './store';
import translations from './translations';

const IntlApp = ({ locale }) => (
    <IntlProvider
        locale={locale || 'en'}
        messages={translations[locale || 'en']}
    >
        <Router />
    </IntlProvider>
);

IntlApp.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = ({ app: { locale } }) => ({ locale });

const AppInner = connect(mapStateToProps)(IntlApp);

const App = () => (
    <div className="App">
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AppInner />
            </PersistGate>
        </Provider>
    </div>
);

export default App;
