import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import validationRules from '../../utils/validationRules';
import { EditableContext } from './EditableRow';

const { Item: FormItem } = Form;

class EditableCell extends React.Component {
    constructor(props) {
        super(props);
        this.getInput = this.getInput.bind(this);
    }

    getInput() {
        const { inputType } = this.props;
        if ('number' === inputType) return <InputNumber min={1} />;
        if ('select' === inputType) {
            const { selectoptions } = this.props;

            return <Select>{selectoptions}</Select>;
        }

        return <Input />;
    }

    render() {
        const {
            editing,
            dataIndex,
            inputType,
            record,
            ...restProps
        } = this.props;

        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    let initialValue;

                    if ('company' === (dataIndex || [])[0] && record.company) initialValue = record.company.firebaseId;
                    else initialValue = record[dataIndex];

                    const index = dataIndex === ['company', 'name'] ? 'company' : dataIndex;

                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem>
                                    {getFieldDecorator(index, {
                                        initialValue,
                                        rules: validationRules[index],
                                    })(this.getInput())}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

EditableCell.propTypes = {
    dataIndex: PropTypes.any,
    editing: PropTypes.bool,
    inputType: PropTypes.string,
    record: PropTypes.shape({
        company: PropTypes.shape({
            firebaseId: PropTypes.string,
        }),
    }),
    selectoptions: PropTypes.arrayOf(PropTypes.node),
};

EditableCell.defaultProps = {
    editing: false,
    inputType: 'text',
    record: {},
    selectoptions: [],
};

export default EditableCell;
