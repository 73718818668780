const defaultState = { hasSelectedUserRole: false };

const envReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case 'DRAWER_SET':
            return { hasSelectedUserRole: payload.selected };
        default:
            return state;
    }
};

export default envReducer;
