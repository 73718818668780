import PropTypes from 'prop-types';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createCompany, getCompanies, getCompany } from '../../actions/company.actions';
import PageLayout from '../layout/PageLayout';
import NewInstance from '../new-instance/NewInstance';
import CompanyTable from './CompanyTable';

const tableName = 'company';

const messages = {
    createAction: 'createCompany_title',
    createSuccess: 'create_company',
};

class CompanyManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableIsLoading: false,
        };
    }

    componentDidMount() {
        const { company, getCompanies: getCompaniesAction, getCompany: getCompanyAction, role } = this.props;
        if (role === 'admin') getCompaniesAction();
        else getCompanyAction(company);
    }

    render() {
        const {
            getCompanies: getCompaniesAction,
            history: { goBack },
            intl,
        } = this.props;
        const { tableIsLoading } = this.state;

        return (
            <PageLayout goBack={goBack} pageKey="companyManagement" title={intl.formatMessage({ id: `${tableName}_management` })}>
                <CompanyTable loading={tableIsLoading} />
                <NewInstance
                    createButtonId="createCompany"
                    createFunction={createCompany}
                    messages={messages}
                    refreshFunction={getCompaniesAction}
                />
            </PageLayout>
        );
    }
}

CompanyManagement.propTypes = {
    company: PropTypes.string.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getCompany: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    intl: PropTypes.any.isRequired,
    role: PropTypes.string.isRequired,
};

const mapStateToProps = ({ signIn: { company, role, userName } }) => ({ company, role, userName });
export default connect(mapStateToProps, { getCompanies, getCompany })(injectIntl(CompanyManagement));
