import './Hello.scss';
import { Button, Input, Modal, message } from 'antd';
import firebase from 'firebase/compat/app';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { ROUTES } from '../../utils/routes';
import PageLayout from '../layout/PageLayout';
import { ROLES_TABLE } from '../user/roles';

class Hello extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.state = { };
    }

    handleClick(page) {
        const remoteConfig = firebase.remoteConfig();
        const { history: { push } } = this.props;
        remoteConfig.fetchAndActivate();

        if (page === 'upload-firmware' && remoteConfig.getString('fw_password') !== localStorage.getItem('fw_password')) return () => this.setState({ isPasswordVisible: true });

        return () => push(page);
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value });
    }

    handlePasswordOk() {
        const { history: { push } } = this.props;
        const { password } = this.state;
        const remoteConfig = firebase.remoteConfig();
        remoteConfig.fetchAndActivate();
        if (remoteConfig.getString('fw_password') === password) {
            localStorage.setItem('fw_password', password);
            push('upload-firmware');
        } else {
            message.error('Mot de passe incorrect');
            this.setState({ isPasswordVisible: false });
        }
    }

    dynamicDocsLink() {
        const { intl } = this.props;
        switch (intl.locale) {
            case 'en': return 'https://www.vmi-technologies.co.uk/pro-space/downloads';
            case 'es': return ' https://www.vmi-technologies.es/espacio-pro/descargas';
            case 'fr': return 'https://www.vmi-technologies.com/documentation-fiches';
            case 'it': return ' https://www.vmi-technologies.co.uk/pro-space/downloads';
            case 'pt': return ' https://www.vmi-technologies.pt/espaco-pro/downloads';
            default: return null;
        }
    }

    render() {
        const { isPasswordVisible } = this.state;
        const { role } = this.props;

        return (
            <PageLayout>
                <div className="manage-buttons">
                    {role && (
                        ROUTES.filter((route) => ROLES_TABLE.filter((cRole) => cRole.name === role)[0].hoc.includes(route.name)).map((route) => (
                            <Button key={route.name} className="responsive" onClick={this.handleClick(route.name)} size="large" type="primary" block>
                                <FormattedMessage id={route.i18n} />
                            </Button>
                        ))
                    )}
                    {role === 'admin' && (
                        <>
                            <Modal onCancel={() => this.setState({ isPasswordVisible: false })} onOk={() => this.handlePasswordOk()} open={isPasswordVisible} title="Mot de passe">
                                <Input onChange={this.handlePasswordChange} />
                            </Modal>
                            <Button key="upload-firmware" className="responsive" onClick={this.handleClick('upload-firmware')} size="large" type="primary" block>
                                <FormattedMessage id="upload_firmware" />
                            </Button>
                        </>
                    )}
                    <Button className="responsive" onClick={() => window.open(this.dynamicDocsLink(), '_blank')} size="large" type="primary" block>
                        <FormattedMessage id="hello_support_pdf" />
                    </Button>
                    <Button className="responsive" onClick={() => window.open('https://www.jeedom.com/market', '_blank')} size="large" type="primary" block>
                        <FormattedMessage id="hello_create_client" />
                    </Button>
                    <Button className="responsive" onClick={() => window.open('https://pro.jeedom.com', '_blank')} size="large" type="primary" block>
                        <FormattedMessage id="hello_e_maintenance" />
                    </Button>
                </div>
            </PageLayout>
        );
    }
}

Hello.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    role: PropTypes.string,
};

const mapStateToProps = ({ signIn: { role, userName } }) => ({ role, userName });

export default connect(mapStateToProps)(injectIntl(Hello));
