import './UserManagement.scss';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createUser, getUsersByCompany } from '../../actions/user.actions';
import PageLayout from '../layout/PageLayout';
import NewInstance from '../new-instance/NewInstance';
import UsersFormItems from './UsersFormItems';
import UserTable from './UserTable';

const tableName = 'users';

const messages = {
    createAction: 'createUser_title',
    createSuccess: 'create_user',
};

class UserManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tableIsLoading: false,
        };
    }

    componentDidMount() {
        const {
            agency,
            company,
            getUsersByCompany: getUsersByCompanyAction,
            role,
        } = this.props;
        getUsersByCompanyAction(company, role, agency);
    }

    render() {
        const { tableIsLoading } = this.state;
        const {
            activeUsers,
            companies,
            getUsersByCompany: getUsersByCompanyAction,
            history: { goBack },
            intl,
            maxUsers,
            role,
        } = this.props;

        return (
            <PageLayout goBack={goBack} pageKey="userManagement" title={intl.formatMessage({ id: `${tableName}_management` })}>
                <div className="max">{maxUsers} <FormattedMessage id="users_max" /></div>
                <UserTable loading={tableIsLoading} />
                {activeUsers < maxUsers ? (
                    <NewInstance
                        createFunction={createUser}
                        messages={messages}
                        refreshFunction={getUsersByCompanyAction}
                        isUsers
                    >
                        <UsersFormItems companies={companies} role={role} />
                    </NewInstance>
                ) : (
                    <div className="actionsContainer">
                        <Button disabled>
                            <FormattedMessage id="createUser_title" />
                        </Button>
                    </div>
                )}
            </PageLayout>
        );
    }
}

UserManagement.propTypes = {
    activeUsers: PropTypes.number,
    agency: PropTypes.string,
    companies: PropTypes.arrayOf(
        PropTypes.shape(),
    ).isRequired,
    company: PropTypes.string.isRequired,
    getUsersByCompany: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    intl: PropTypes.any.isRequired,
    maxUsers: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
};

const mapStateToProps = ({ companies: { companies, adminClient: { maxUsers, activeUsers } }, signIn: { agency, company, role } }) => ({ agency, company, companies, role, maxUsers, activeUsers });
export default connect(mapStateToProps, { getUsersByCompany })(injectIntl(UserManagement));
