import PropTypes from 'prop-types';

const checkLang = (ComposedComponent) => {
    const checkLangInner = (props) => {
        const { match: { params: { lang } }, history: { push } } = props;
        if ('fr' !== lang && 'it' !== lang && 'en' !== lang && 'es' !== lang && 'pt' !== lang) {
            push('/404');

            return null;
        }

        return <ComposedComponent {...props} />;
    };

    checkLangInner.propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                lang: PropTypes.string.isRequired,
            }),
        }).isRequired,
    };

    return checkLangInner;
};

export default checkLang;
