import './Login.scss';
import '@ant-design/compatible/assets/index.css';
import { Form } from '@ant-design/compatible';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Input, message } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { login } from '../../actions/login.actions';
import { setUser } from '../../actions/signIn.actions';
import { inputToLowerCase } from '../../utils/inputLowerCase';
import validationRules from '../../utils/validationRules';
import PageLayout from '../layout/PageLayout';

const { Item: FormItem } = Form;

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            loading: false,
        };
    }

    handleClick(toPage) {
        const { history: { push } } = this.props;

        return () => push(toPage);
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
            form: { validateFields },
            history: { push },
            intl,
            setUser: setUserAction,
        } = this.props;
        validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                const values2 = values;
                values2.lang = intl.locale;
                login(values2, setUserAction)
                    .then((result) => {
                        message.success(intl.formatMessage({ id: `message_success_${result}` }));
                        setTimeout(() => push('/hello'));
                    }).catch((error) => {
                        const translateString = `message_fail_${error.message}`;
                        if (intl.messages[translateString]) message.error(intl.formatMessage({ id: translateString }));
                        else message.error(intl.formatMessage({ id: 'message_fail_unknown' }));
                    }).finally(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    }

    render() {
        const { intl, form: { getFieldDecorator } } = this.props;
        const { loading } = this.state;

        return (
            <PageLayout title={intl.formatMessage({ id: 'login_title' })}>
                <div className="signin-container">
                    <img alt="logo" className="logo" src={`${process.env.PUBLIC_URL}/assets/img/logo.svg`} />
                    <Card>
                        <Form className="login-form" onSubmit={this.handleSubmit}>
                            <FormItem>
                                {getFieldDecorator('id', {
                                    rules: validationRules.username,
                                })(
                                    <Input
                                        onChange={inputToLowerCase}
                                        placeholder={intl.formatMessage({ id: 'login_username' })}
                                        prefix={<UserOutlined />}
                                    />,
                                )}
                            </FormItem>
                            <FormItem>
                                {getFieldDecorator('password', {
                                    rules: validationRules.password,
                                })(
                                    <Input.Password
                                        placeholder={intl.formatMessage({ id: 'login_password' })}
                                        prefix={<LockOutlined />}
                                        type="password"
                                    />,
                                )}
                            </FormItem>
                            <FormItem>
                                <Button className="login-form-button" htmlType="submit" loading={loading} type="primary" block>
                                    {intl.formatMessage({ id: 'login_loginButton' })}
                                </Button>
                            </FormItem>
                        </Form>
                        <Link to="/first-connection">
                            <FormattedMessage id="login_first_connection" />
                        </Link>
                        <Link to="/forgot-password">
                            <FormattedMessage id="login_forgot_password" />
                        </Link>
                    </Card>
                    <div className="help">
                        <Button onClick={() => window.open('https://www.jeedom.com/market', '_blank')} type="link" block>
                            <FormattedMessage id="hello_create_client" />
                        </Button>
                        <Button onClick={() => window.open('https://pro.jeedom.com', '_blank')} type="link" block>
                            <FormattedMessage id="hello_e_maintenance" />
                        </Button>
                        <Button onClick={() => window.open('https://www.vmi-technologies.com/documentation-fiches', '_blank')} type="link" block>
                            <FormattedMessage id="hello_support_pdf" />
                        </Button>
                        <Button onClick={this.handleClick('/support')} type="link" block>
                            <FormattedMessage id="hello_support_video" />
                        </Button>
                    </div>
                </div>
            </PageLayout>
        );
    }
}

Login.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    intl: PropTypes.any.isRequired,
    setUser: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({ });

export default connect(mapStateToProps, { setUser })(injectIntl(Form.create({ name: 'normal_login' })(Login)));
