import './Privacy.scss';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import MarkdownView from 'react-showdown';
import PageLayout from '../layout/PageLayout';

class Privacy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            markdown: '',
        };
    }

    componentDidMount() {
        const { match: { params: { lang } } } = this.props;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', `${process.env.PUBLIC_URL}/assets/md/privacy/${lang}.md`, true);
        xhr.onreadystatechange = () => {
            if (4 === xhr.readyState && 200 === xhr.status) this.setState({ markdown: xhr.responseText });
        };
        xhr.send();
    }

    render() {
        const { history: { goBack } } = this.props;
        const { markdown } = this.state;

        return (
            <PageLayout goBack={goBack} noLangIcons>
                <MarkdownView markdown={markdown} />
            </PageLayout>
        );
    }
}

Privacy.propTypes = {
    app: PropTypes.shape({
        locale: PropTypes.string.isRequired,
    }),
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            lang: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired };

export default connect()(Privacy);
