import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducer/index.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['app', 'signIn'],
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(thunkMiddleware),
    ),
);

export const persistor = persistStore(store);
